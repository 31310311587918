<template>
  <h4>
    ReportPlusCreate
  </h4>
</template>

<script>
import SubmitFormInteractor from "@/business/report-plus-create/submit-form";
import InitReportPlusCreateInteractor from "@/business/report-plus-create/init-report-plus-create";
import ReportPlusCreateScreenController from "@/adapters/controllers/screen-report-plus-create";

export default {
  screen_name: "report-plus-create",
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initReportPlusCreate: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ReportPlusCreateScreenController);

    //{ INTERACTORS
    this.interactors.initReportPlusCreate = this.$injector.get(
      InitReportPlusCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    }
  }
};
</script>

<style lang="scss"></style>
